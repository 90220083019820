import React, { useState } from 'react';
import cn from 'classnames';
import {
  Form,
  IntersectionAnimation,
  LayoutProvider
} from '@livewire/common/components';
import { SliceConfig } from '@livewire/website/src/components';
import { ILeadCapture } from '@livewire/sanity';
import * as styles from './styles.module.scss';

interface IProps {
  data: ILeadCapture;
}

const LeadCapture = ({
  data: {
    sliceConfig,
    headingStyle,
    heading,
    body,
    confirmationHeading,
    confirmationMessage
  }
}: IProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const onSubmit = async (values) => {
    //
    // todo: re-enable this when we're ready to capture leads
    //
    await fetch('/api/capture-lead', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    });
    // console.log(values, 'values');
    // todo: error handling
    setIsSubmitted(true);
  };

  return (
    // todo: create a website slice config with different padding settings
    <SliceConfig className={styles.container} config={sliceConfig}>
      <LayoutProvider grid>
        <header className={styles.header}>
          <IntersectionAnimation>
            <h3
              className={cn('d1', styles.heading, {
                [styles.small]: headingStyle === 'small'
              })}
            >
              {heading}
            </h3>
          </IntersectionAnimation>
        </header>

        {(isSubmitted && (
          <div className={styles.successMessage}>
            <IntersectionAnimation>
              <h5 className="h1">{confirmationHeading}</h5>
            </IntersectionAnimation>

            <IntersectionAnimation>
              <p className="h6">{confirmationMessage}</p>
            </IntersectionAnimation>
          </div>
        )) || (
          <article className={styles.text}>
            <IntersectionAnimation>
              <p className="b1">{body}</p>
            </IntersectionAnimation>

            <IntersectionAnimation className={styles.form}>
              <Form
                type="lead"
                onSubmit={onSubmit}
                setIsSubmitted={setIsSubmitted}
              />
            </IntersectionAnimation>
          </article>
        )}
      </LayoutProvider>
    </SliceConfig>
  );
};

export default LeadCapture;
