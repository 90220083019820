import React from 'react';
import { SlicePreview } from '@livewire/website/src/components';
import { ISlicePreview } from '@livewire/website/types';
import featureGroq from './queries.groq';
import Feature from './';

const PreviewFeature = ({
  data,
  pageSlug,
  pageType,
  sliceIndex
}: ISlicePreview) => {
  return (
    <SlicePreview
      data={data}
      groqQuery={featureGroq}
      pageSlug={pageSlug}
      pageType={pageType}
      sliceComponent={Feature}
      sliceIndex={sliceIndex}
    />
  );
};

export default PreviewFeature;
