// extracted by mini-css-extract-plugin
export var active = "styles-module--active--98f02";
export var banner = "styles-module--banner--c90ac";
export var bannerTextAppear = "styles-module--banner-text-appear--cac88";
export var container = "styles-module--container--15796";
export var desktop = "1340px";
export var giant = "2200px";
export var heading = "styles-module--heading--94a44";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var logo = "styles-module--logo--3b91f";
export var mobile = "400px";
export var scaleText = "styles-module--scaleText--13df8";
export var scaleTextContainer = "styles-module--scaleTextContainer--ece97";
export var scaleTextContent = "styles-module--scaleTextContent--62084";
export var scaleTextLower = "styles-module--scaleTextLower--98e70";
export var scaleTextUpper = "styles-module--scaleTextUpper--0af4b";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var subheading = "styles-module--subheading--f4607";
export var tablet = "768px";
export var text = "styles-module--text--8e4ea";