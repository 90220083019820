import React from 'react';
import { SlicePreview } from '@livewire/website/src/components';
import { ISlicePreview } from '@livewire/website/types';
import richTextGroq from './queries.groq';
import RichText from './';

const PreviewRichText = ({
  data,
  pageSlug,
  pageType,
  sliceIndex
}: ISlicePreview) => {
  return (
    <SlicePreview
      data={data}
      groqQuery={richTextGroq}
      pageSlug={pageSlug}
      pageType={pageType}
      sliceComponent={RichText}
      sliceIndex={sliceIndex}
    />
  );
};

export default PreviewRichText;
