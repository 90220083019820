// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--d07c6";
export var appearDown = "styles-module--appear-down--2a01d";
export var appearLeft = "styles-module--appear-left--5c862";
export var appearRight = "styles-module--appear-right--906f7";
export var appearUp = "styles-module--appear-up--e4464";
export var captions = "styles-module--captions--d1644";
export var container = "styles-module--container--eb305";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--7f1af";
export var mediaContainer = "styles-module--mediaContainer--e979c";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var twoCol = "styles-module--twoCol--bd327";