import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import * as styles from './styles.module.scss';

const Logo = ({ color = `#ffffff` }) => {
  const timeline = [
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0]
    ],
    [
      [1, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0]
    ],
    [
      [1, 1, 0, 0],
      [1, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0]
    ],
    [
      [1, 1, 1, 0],
      [1, 1, 0, 0],
      [1, 0, 0, 0],
      [0, 0, 0, 0]
    ],
    [
      [1, 1, 1, 1],
      [1, 1, 1, 0],
      [1, 1, 0, 0],
      [1, 0, 0, 0]
    ],
    [
      [0, 1, 1, 1],
      [1, 1, 1, 1],
      [1, 1, 1, 0],
      [1, 1, 0, 0]
    ],
    [
      [0, 0, 1, 1],
      [0, 1, 1, 1],
      [1, 1, 1, 1],
      [1, 1, 1, 0]
    ],
    [
      [0, 0, 0, 1],
      [0, 0, 1, 1],
      [0, 1, 1, 1],
      [1, 1, 1, 1]
    ],
    [
      [0, 0, 0, 0],
      [0, 0, 0, 1],
      [0, 0, 1, 1],
      [0, 1, 1, 1]
    ],
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 1],
      [0, 0, 1, 1]
    ],
    [
      [0, 1, 0, 0],
      [1, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 1]
    ],
    [
      [0, 1, 1, 0],
      [1, 0, 0, 0],
      [1, 0, 0, 0],
      [0, 0, 0, 0]
    ],
    [
      [0, 1, 1, 0],
      [1, 0, 1, 0],
      [1, 0, 0, 0],
      [1, 0, 0, 0]
    ],
    [
      [0, 1, 1, 0],
      [1, 0, 1, 1],
      [1, 0, 0, 0],
      [1, 1, 0, 0]
    ],
    [
      [0, 1, 1, 0],
      [1, 0, 1, 1],
      [1, 0, 0, 1],
      [1, 1, 1, 0]
    ]
  ];

  const [activeMatrixIndex, setActiveMatrixIndex] = useState(0);

  let row = -1;

  useEffect(() => {
    if (activeMatrixIndex >= timeline.length - 1) {
      return;
    }

    setTimeout(() => {
      setActiveMatrixIndex(
        activeMatrixIndex >= timeline.length - 1 ? 0 : activeMatrixIndex + 1
      );
    }, 40);
  }, [activeMatrixIndex]);

  return (
    <div className={styles.container}>
      <div className={styles.matrixContainer}>
        <div className={styles.matrix}>
          {Array(16)
            .fill(null)
            .map((_, index) => {
              const node = index % 4;

              if (node === 0) {
                row += 1;
              }

              const active = timeline?.[activeMatrixIndex]?.[row]?.[node] === 1;

              return (
                <div key={`logo-node-${index}`} className={cn(styles.node)}>
                  <div
                    className={cn(styles.background, {
                      [styles.active]: active
                    })}
                    style={{ background: active ? color : `transparent` }}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Logo;
