// extracted by mini-css-extract-plugin
export var body = "styles-module--body--4107e";
export var container = "styles-module--container--96713";
export var content = "styles-module--content--b2f24";
export var desktop = "1340px";
export var form = "styles-module--form--68737";
export var giant = "2200px";
export var grid = "styles-module--grid--1f4c3";
export var heading = "styles-module--heading--404e0";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var svg = "styles-module--svg--92629";
export var tablet = "768px";
export var text = "styles-module--text--b5789";