import React, { useState } from 'react';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { Button, SVG } from '@livewire/common/components';
import { useDataLayer } from '@livewire/common/hooks';
import { getFormFields } from '@livewire/common/utils';
import * as styles from './styles.module.scss';
import FormField from './components/FormField';

const Form = ({
  type,
  title,
  onSubmit,
  formSparkId,
  submitButton,
  className,
  setIsSubmitted,
  password,
  reValidateMode
}) => {
  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onSubmit',
    reValidateMode: reValidateMode || 'onChange'
  });
  const { errors, isSubmitting } = formState;

  const [submissionError, setSubmissionError] = useState('');
  const [submissionSuccess, setSubmissionSuccess] = useState('');
  const sendToDataLayer = useDataLayer();

  const fields = getFormFields(type);

  const resetForm = () => {
    if (setIsSubmitted) {
      const resetTimeout = setTimeout(() => {
        reset();
        setIsSubmitted(false);
      }, 3000);

      return () => {
        clearTimeout(resetTimeout);
      };
    }
  };

  const onSubmitHandler = async (values) => {
    try {
      if (onSubmit) {
        await onSubmit(values);
      } else {
        const url = `https://submit-form.com/${formSparkId || ''}`; // pass formSparkId to send to formSpark
        const config = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(values)
        };
      }
      resetForm();
      setSubmissionSuccess(`Thank you, you've been signed up`);
      sendToDataLayer('contact_form_submission');
    } catch (error) {
      console.error('Error submitting form', error);
      setSubmissionError('Oops something went wrong, please try again');
    }
  };

  return (
    <section>
      {title && <h4 className={styles.title}>{title}</h4>}
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        className={`${styles.form} ${className || ''}`}
      >
        <button
          type="submit"
          aria-label="submit"
          aria-hidden="true"
          style={{ display: 'none' }}
        />
        {fields?.[0] &&
          fields.map((field, index) => {
            const hasError = errors[field.name];

            let fieldProps = { ...field };

            if (type.toLowerCase() === 'password') {
              fieldProps = {
                ...fieldProps,
                ...password,
                validation: {
                  ...field.validation,
                  validate: (value: string) =>
                    value === password.password ||
                    'Incorrect password. Please try again.'
                }
              };
            }

            return (
              <div
                key={`form-field-${field?.label || field?.name || index}`}
                className={cn(styles.field, field.className)}
              >
                <FormField
                  {...fieldProps}
                  register={register}
                  hasError={hasError}
                />
                <input
                  {...register('_gotcha')}
                  type="checkbox"
                  name="_gotcha"
                  style={{ display: 'none' }}
                  tabIndex={-1}
                  autoComplete="off"
                />
                <div
                  className={`${styles.fieldError} ${
                    hasError ? 'active' : 'inactive'
                  }`}
                >
                  {hasError && (
                    <>
                      {type === 'password' && (
                        <figure className={styles.errorSvg}>
                          <SVG svg="warningTriangle" />
                        </figure>
                      )}

                      <span className="caption">
                        {errors[field?.name]?.message ||
                          field.validationMessage ||
                          'This field is required'}
                      </span>
                    </>
                  )}
                </div>
              </div>
            );
          })}

        <Button
          buttonType="submit"
          aria-label="submit"
          className={`button ${styles.button}`}
          disabled={isSubmitting}
          theme="lightOnDark"
          iconRight={submitButton?.hideIcon ? '' : 'arrowRight'}
        >
          {isSubmitting
            ? submitButton?.submissionText || 'Submitting'
            : submitButton?.defaultText || 'Submit'}
        </Button>
      </form>
      {submissionError && (
        <span className={cn('caption', styles.submissionError)}>
          {submissionError}
        </span>
      )}

      {/* default submission success message */}
      {/* {submissionSuccess && (
        <span className={styles.submissionSuccess}>{submissionSuccess}</span>
      )} */}
    </section>
  );
};

export default Form;
