// extracted by mini-css-extract-plugin
export var button = "styles-module--button--40761";
export var desktop = "1340px";
export var errorSvg = "styles-module--errorSvg--bfc70";
export var field = "styles-module--field--6ff87";
export var fieldError = "styles-module--fieldError--4c3f3";
export var form = "styles-module--form--f0ec2";
export var giant = "2200px";
export var label = "styles-module--label--357f5";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var newsletterButton = "styles-module--newsletterButton--4a395";
export var newsletterField = "styles-module--newsletterField--ff186";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var submissionError = "styles-module--submissionError--4e3d0";
export var tablet = "768px";
export var title = "styles-module--title--9607b";