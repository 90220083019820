import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import * as styles from './styles.module.scss';

const Matrix = () => {
  const timeline = [
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0]
    ],
    [
      [1, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0]
    ],
    [
      [1, 1, 0, 0],
      [1, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0]
    ],
    [
      [1, 1, 1, 0],
      [1, 1, 0, 0],
      [1, 0, 0, 0],
      [0, 0, 0, 0]
    ],
    [
      [1, 1, 1, 1],
      [1, 1, 1, 0],
      [1, 1, 0, 0],
      [1, 0, 0, 0]
    ],
    [
      [0, 1, 1, 1],
      [1, 1, 1, 1],
      [1, 1, 1, 0],
      [1, 1, 0, 0]
    ],
    [
      [0, 0, 1, 1],
      [0, 1, 1, 1],
      [1, 1, 1, 1],
      [1, 1, 1, 0]
    ],
    [
      [0, 0, 0, 1],
      [0, 0, 1, 1],
      [0, 1, 1, 1],
      [1, 1, 1, 1]
    ],
    [
      [0, 0, 0, 0],
      [0, 0, 0, 1],
      [0, 0, 1, 1],
      [0, 1, 1, 1]
    ],
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 1],
      [0, 0, 1, 1]
    ],
    [
      [0, 1, 0, 0],
      [1, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 1]
    ],
    [
      [0, 1, 1, 0],
      [1, 0, 0, 0],
      [1, 0, 0, 0],
      [0, 0, 0, 0]
    ],
    [
      [0, 1, 1, 1],
      [1, 0, 1, 0],
      [1, 0, 0, 0],
      [1, 0, 0, 0]
    ],
    [
      [0, 1, 1, 1],
      [1, 0, 1, 1],
      [1, 0, 0, 0],
      [1, 1, 0, 0]
    ],
    [
      [0, 1, 1, 1],
      [1, 0, 1, 1],
      [1, 0, 0, 1],
      [1, 1, 1, 0]
    ],
    [
      [0, 1, 1, 1],
      [1, 0, 1, 1],
      [1, 0, 0, 1],
      [1, 1, 1, 0]
    ],
    [
      [0, 1, 1, 1],
      [1, 0, 1, 1],
      [1, 0, 0, 1],
      [1, 1, 1, 0]
    ],
    [
      [0, 1, 1, 1],
      [1, 0, 1, 1],
      [1, 0, 0, 1],
      [1, 1, 1, 0]
    ],
    [
      [0, 1, 1, 1],
      [1, 0, 1, 1],
      [1, 0, 0, 1],
      [1, 1, 1, 0]
    ],
    [
      [0, 1, 1, 1],
      [1, 0, 1, 1],
      [1, 0, 0, 1],
      [1, 1, 1, 0]
    ],
    [
      [0, 1, 1, 1],
      [1, 0, 1, 1],
      [1, 0, 0, 1],
      [1, 1, 1, 0]
    ],
    [
      [0, 1, 1, 1],
      [1, 0, 1, 1],
      [1, 0, 0, 1],
      [1, 1, 1, 0]
    ],
    [
      [0, 1, 1, 1],
      [1, 0, 1, 1],
      [1, 0, 0, 1],
      [1, 1, 1, 0]
    ],
    [
      [0, 1, 1, 1],
      [1, 0, 1, 1],
      [1, 0, 0, 1],
      [1, 1, 1, 0]
    ],
    [
      [0, 1, 1, 1],
      [1, 0, 1, 1],
      [1, 0, 0, 1],
      [1, 1, 1, 0]
    ],
    [
      [0, 1, 1, 1],
      [1, 0, 1, 1],
      [1, 0, 0, 1],
      [1, 1, 1, 0]
    ],
    [
      [0, 0, 0, 1],
      [0, 0, 1, 1],
      [0, 0, 0, 1],
      [1, 1, 1, 0]
    ],
    [
      [0, 0, 0, 0],
      [0, 0, 0, 1],
      [0, 0, 0, 1],
      [0, 1, 1, 0]
    ],
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0]
    ]
  ];

  const [activeMatrixIndex, setActiveMatrixIndex] = useState(0);

  let row = -1;

  useEffect(() => {
    if (activeMatrixIndex >= timeline.length - 1) {
      return;
    }

    setTimeout(() => {
      setActiveMatrixIndex(
        activeMatrixIndex >= timeline.length - 1 ? 0 : activeMatrixIndex + 1
      );
    }, 40);
  }, [activeMatrixIndex]);

  return (
    <div className={styles.container}>
      <div className={styles.matrixContainer}>
        <div className={styles.matrix}>
          {Array(16)
            .fill(null)
            .map((_, index) => {
              const node = index % 4;

              if (node === 0) {
                row += 1;
              }

              return (
                <div
                  className={cn(styles.node, {
                    [styles.active]:
                      timeline[activeMatrixIndex][row][node] === 1
                  })}
                >
                  <></>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Matrix;
