import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import { AudioVideo, Image } from '@livewire/website/src/components';
import { ICardGridMediaCard } from '@livewire/sanity';
import { getSlugByPageType } from '@livewire/common/utils';

import * as styles from './styles.module.scss';

interface ICard {
  data: ICardGridMediaCard;
  carousel?: boolean;
}

interface IConditionalWrapper {
  children: React.ReactNode;
  condition: boolean;
  wrapper: (children: React.ReactNode) => React.ReactNode;
}

const MediaCard = ({
  data: { heading, description, media, link },
  carousel
}: ICard) => {
  let slug = '';

  if (link?.[0]) {
    slug = getSlugByPageType(link?.[0]);
  }

  const ConditionalWrapper = ({
    condition,
    wrapper,
    children
  }: IConditionalWrapper) => (condition ? wrapper(children) : children);

  return (
    <ConditionalWrapper
      condition={slug.length > 0}
      wrapper={(children) =>
        link?.[0]?._type === 'externalUrl' ? (
          <a href={slug}>{children}</a>
        ) : (
          <Link to={slug}>{children}</Link>
        )
      }
    >
      <article
        className={cn(styles.container, {
          [styles.carousel]: carousel,
          [styles.hoverable]: slug.length > 0
        })}
      >
        {(media?.video?.url || media?.image?.asset) && (
          <figure className={cn(styles.media, { [styles.carousel]: carousel })}>
            {(media?.video?.url && (
              <AudioVideo className={styles.video} data={media.video} />
            )) || <>{media?.image?.asset && <Image image={media.image} />}</>}
          </figure>
        )}

        <div className={cn(styles.content, { [styles.carousel]: carousel })}>
          {heading && <h5 className={cn(styles.heading, `b1`)}>{heading}</h5>}
          {description && <p className="b3">{description}</p>}
        </div>
      </article>
    </ConditionalWrapper>
  );
};

export default MediaCard;
