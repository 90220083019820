import React, { useEffect } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import {
  Grid,
  IntersectionAnimation,
  UniversalLink
} from '@livewire/common/components';
import { HoverLogo } from '@livewire/website/src/components';
import cn from 'classnames';
import { useKeyPress } from '@livewire/common/hooks';
import { useApp } from '@livewire/website/src/hooks';
import { IMenu } from '@livewire/sanity';

import * as styles from './styles.module.scss';

interface IProps {
  pathname: string;
}

const Header = ({ pathname }: IProps) => {
  const settings: IMenu = useStaticQuery(graphql`
    query {
      sanitySettings {
        menu {
          links {
            _key
            ...UniversalLinkWebsiteFragment
          }

          ctaButton {
            ...UniversalLinkWebsiteFragment
          }
        }
      }
    }
  `)?.sanitySettings?.menu;

  const { isMenuOpen, setIsMenuOpen } = useApp();
  const escKeyPressed = useKeyPress('Escape');

  const menu = settings?.links;
  const button = settings?.ctaButton;
  const animationStagger = 100;

  useEffect(() => {
    if (escKeyPressed) {
      setIsMenuOpen(false);
    }
  }, [escKeyPressed]);

  return (
    <header className={styles.container}>
      <Grid className={styles.content}>
        <div className={styles.wordmark}>
          <Link to="/" aria-label="Go to homepage">
            <div className={styles.hoverLogo}>
              <HoverLogo color="white" small />
            </div>
          </Link>
        </div>

        <ul className={styles.menuDesktop}>
          {menu.map((item, index) => {
            const { _key, link } = item || {};

            const slug = link?.[0]?.page?.slug?.current;

            const currentPage = slug === pathname;

            return (
              <li key={`header-menu-link-${_key}`}>
                <IntersectionAnimation
                  margin="0px"
                  delay={animationStagger * index}
                >
                  <UniversalLink
                    className={cn(styles.link, {
                      [styles.active]: currentPage
                    })}
                    link={item}
                    variant="tab"
                  />
                </IntersectionAnimation>
              </li>
            );
          })}
        </ul>

        <IntersectionAnimation
          className={styles.contactButton}
          delay={animationStagger * menu?.length}
        >
          <UniversalLink link={button} theme="lightOnDark" />
        </IntersectionAnimation>

        <div className={styles.menuContainer}>
          <button
            className={cn(styles.menuButton, {
              [styles.active]: isMenuOpen
            })}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle menu"
          >
            <div className={cn(styles.menuLine, styles.menuLine0)} />
            <div className={cn(styles.menuLine, styles.menuLine1)} />
            <div className={cn(styles.menuLine, styles.menuLine2)} />
          </button>
        </div>
      </Grid>
    </header>
  );
};

export default Header;
