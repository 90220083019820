// extracted by mini-css-extract-plugin
export var carousel = "styles-module--carousel--170b9";
export var container = "styles-module--container--b6adf";
export var content = "styles-module--content--e264a";
export var desktop = "1340px";
export var giant = "2200px";
export var heading = "styles-module--heading--5c8ee";
export var hoverable = "styles-module--hoverable--73c62";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--21876";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var video = "styles-module--video--b1ce1";