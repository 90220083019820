import React from 'react';
import { Grid, PortableTextRenderer } from '@livewire/common/components';
import { IRichText } from '@livewire/sanity';
import { SliceConfig } from '@livewire/website/src/components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import * as styles from './styles.module.scss';

interface IProps {
  data: IRichText;
}

const RichText = ({ data: { sliceConfig, _rawText, text } }: IProps) => {
  const textContent = _rawText || text;

  return (
    <SliceConfig className={styles.container} config={sliceConfig}>
      <Grid>
        <div className={styles.text}>
          <div>
            <PortableTextRenderer
              rawText={textContent}
              gatsbyImageComponent={GatsbyImage}
              getGatsbyImageData={getGatsbyImageData}
            />
          </div>
        </div>
      </Grid>
    </SliceConfig>
  );
};

export default RichText;
