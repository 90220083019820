import React from 'react';
import { SlicePreview } from '@livewire/website/src/components';
import { ISlicePreview } from '@livewire/website/types';
import magicAtfGroq from './queries.groq';
import MagicAtf from './';

const PreviewMagicAtf = ({
  data,
  pageSlug,
  pageType,
  sliceIndex
}: ISlicePreview) => {
  return (
    <SlicePreview
      data={data}
      groqQuery={magicAtfGroq}
      pageSlug={pageSlug}
      pageType={pageType}
      sliceComponent={MagicAtf}
      sliceIndex={sliceIndex}
    />
  );
};

export default PreviewMagicAtf;
