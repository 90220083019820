// extracted by mini-css-extract-plugin
export var canvas = "styles-module--canvas--245b0";
export var canvasAppear = "styles-module--canvas-appear--5425d";
export var container = "styles-module--container--17959";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var scrollArea = "styles-module--scrollArea--5b6f5";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";