// extracted by mini-css-extract-plugin
export var body = "styles-module--body--87bb4";
export var button = "styles-module--button--26b02";
export var buttons = "styles-module--buttons--b544e";
export var caption = "styles-module--caption--f7649";
export var captions = "styles-module--captions--b698c";
export var container = "styles-module--container--e9617";
export var content = "styles-module--content--fed2c";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--4b2cd";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var left = "styles-module--left--827f9";
export var media = "styles-module--media--6fdd6";
export var mediaContainer = "styles-module--mediaContainer--7d499";
export var mobile = "400px";
export var right = "styles-module--right--63e80";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var square = "styles-module--square--8e420";
export var subcaption = "styles-module--subcaption--f87e6";
export var tablet = "768px";
export var text = "styles-module--text--5e821";
export var video = "styles-module--video--a4d4b";