import React from 'react';
import cn from 'classnames';
import { ICardGridCard } from '@livewire/sanity';
import { Link } from 'gatsby';
import * as styles from './styles.module.scss';
import { getSlugByPageType } from '@livewire/common/utils';

interface ICard {
  data: ICardGridCard;
  carousel?: boolean;
}

interface IConditionalWrapper {
  children: React.ReactNode;
  condition: boolean;
  wrapper: (children: React.ReactNode) => React.ReactNode;
}

const Card = ({
  data: { heading, subheading, description, link },
  carousel
}: ICard) => {
  let slug = '';

  if (link?.[0]) {
    slug = getSlugByPageType(link?.[0]);
  }

  const ConditionalWrapper = ({
    condition,
    wrapper,
    children
  }: IConditionalWrapper) => (condition ? wrapper(children) : children);

  return (
    <ConditionalWrapper
      condition={slug.length > 0}
      wrapper={(children) =>
        link?.[0]?._type === 'externalUrl' ? (
          <a href={slug}>{children}</a>
        ) : (
          <Link to={slug}>{children}</Link>
        )
      }
    >
      <article
        className={cn(styles.container, { [styles.carousel]: carousel })}
      >
        <header>
          {/* todo: asg update for .d2 */}
          <h3 className="d1">{heading}</h3>
          <h3 className="h2">{subheading}</h3>
        </header>

        <div>{description && <p className="caption">{description}</p>}</div>
      </article>
    </ConditionalWrapper>
  );
};

export default Card;
