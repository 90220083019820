import React from 'react';
import cn from 'classnames';

import * as styles from './styles.module.scss';

interface IProps {
  variant?: 'filled' | 'outline';
  children: React.ReactNode;
}

const Tag = ({ variant = 'filled', children }: IProps) => {
  return (
    <p className={cn(styles.container, styles[variant])}>
      <span className="caption">{children}</span>
    </p>
  );
};

export default Tag;
