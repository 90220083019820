// extracted by mini-css-extract-plugin
export var active = "styles-module--active--bfa06";
export var contactButton = "styles-module--contactButton--f4f79";
export var container = "styles-module--container--bf3af";
export var content = "styles-module--content--a041d";
export var desktop = "1340px";
export var giant = "2200px";
export var headerHeight = "56px";
export var hoverLogo = "styles-module--hoverLogo--79ac2";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var link = "styles-module--link--3d825";
export var menuButton = "styles-module--menuButton--69e98";
export var menuContainer = "styles-module--menuContainer--21d78";
export var menuDesktop = "styles-module--menuDesktop--c568f";
export var menuLine = "styles-module--menuLine--08c9c";
export var menuLine0 = "styles-module--menuLine0--13ad7";
export var menuLine1 = "styles-module--menuLine1--ae2b3";
export var menuLine2 = "styles-module--menuLine2--06384";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var wordmark = "styles-module--wordmark--0280f";