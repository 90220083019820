import React from 'react';
import cn from 'classnames';
import {
  Grid,
  PortableTextRenderer,
  UniversalLink
} from '@livewire/common/components';
import { SliceConfig } from '@livewire/website/src/components';
import { IArticle } from '@livewire/sanity';
import * as styles from './styles.module.scss';

interface IProps {
  data: IArticle;
}

// Have commented out what I think is unneccesary logic related to the Universal Link
// Need to verify nothing has changed on frontend, then can remove comments

const Article = ({
  data: { sliceConfig, link, media, _rawText, textAlign }
}: IProps) => {
  // let prefix;

  // if (link?.link?.[0]?._type) {
  //   switch (link.link[0]._type) {
  //     case `referenceWebsiteCaseStudy`:
  //       prefix = `/case-studies/`;
  //       break;

  //     case `referenceWebsiteNewsArticle`:
  //       prefix = `/news/`;
  //       break;

  //     case `page`:
  //     default:
  //       prefix = `/`;
  //       break;
  //   }
  // }

  return (
    <SliceConfig config={sliceConfig}>
      <div
        className={cn(styles.container, {
          [styles.swap]: textAlign === `left`
        })}
      >
        <Grid>
          <figure className={styles.media}>
            {media?.image?.asset && (
              <img src={media.image.asset.url} alt={media.image.altText} />
            )}
          </figure>

          <div className={styles.text}>
            <div>
              <PortableTextRenderer rawText={_rawText} />
            </div>

            <UniversalLink link={link} className={styles.button} />

            {/* {link?.link?.[0] && (
              <Button
                to={`${prefix}${link.link[0].page.slug.current}`}
                className={styles.button}
              >
                {link.text}
              </Button>
            )} */}
          </div>
        </Grid>
      </div>
    </SliceConfig>
  );
};

export default Article;
