// extracted by mini-css-extract-plugin
export var block = "styles-module--block--31293";
export var body = "styles-module--body--0bebc";
export var bottom = "styles-module--bottom--2af7c";
export var button = "styles-module--button--5bbab";
export var buttonContainer = "styles-module--buttonContainer--c9257";
export var container = "styles-module--container--d4423";
export var content = "styles-module--content--a4a2f";
export var desktop = "1340px";
export var fullWidth = "styles-module--fullWidth--bbc47";
export var giant = "2200px";
export var header = "styles-module--header--c657b";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--8e57c";
export var mediaContainer = "styles-module--mediaContainer--17801";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var tags = "styles-module--tags--970c1";
export var text = "styles-module--text--26dfe";
export var video = "styles-module--video--78974";