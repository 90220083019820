/**
 * Image component with support for Sanity previews
 */

import React from 'react';
import cn from 'classnames';
import { IAltImage } from '@livewire/sanity';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from './styles.module.scss';

interface IProps {
  image?: IAltImage;
  className?: string;
  style?: React.CSSProperties;
}

const Image = ({ className, image, style }: IProps) => {
  if (!image) return null;

  const isPreview = image.asset.gatsbyImageData === undefined;

  return (
    <div style={style} className={cn(className, styles.container)}>
      {isPreview ? (
        <img
          src={image.asset.url}
          alt={image.altText}
          className={styles.image}
        />
      ) : (
        <GatsbyImage
          image={image.asset.gatsbyImageData}
          alt={image.altText || ''}
          className={styles.gatsbyImage}
        />
      )}
    </div>
  );
};

export default Image;
