// extracted by mini-css-extract-plugin
export var carousel = "styles-module--carousel--96135";
export var container = "styles-module--container--0ec28";
export var desktop = "1340px";
export var excerpt = "styles-module--excerpt--7f876";
export var giant = "2200px";
export var heading = "styles-module--heading--4efd7";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--b068a";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var text = "styles-module--text--f86fc";