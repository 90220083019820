import React, { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { globalHistory } from '@reach/router';
import * as styles from '@livewire/common/styles/disableScroll.module.scss';

export interface IAppContext {
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  windowLocation: Location | undefined;
}

export const AppContext = createContext<IAppContext>({} as IAppContext);

interface IProps {
  children: React.ReactNode;
}

const AppProvider = ({ children }: IProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [windowLocation, setWindowLocation] = useState<Location | undefined>();

  useEffect(() => {
    setWindowLocation(window.location);

    return globalHistory.listen(({ location }) => {
      setWindowLocation(location);
    });
  }, []);

  // Close menu on page change
  useEffect(() => {
    setIsMenuOpen(false);
  }, [windowLocation]);

  // Disable page scroll
  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add(styles.disableScroll);
    } else {
      document.body.classList.remove(styles.disableScroll);
    }
  }, [isMenuOpen]);

  const contextProps = useMemo(
    () => ({
      isMenuOpen,
      setIsMenuOpen,
      windowLocation
    }),
    [isMenuOpen, setIsMenuOpen, windowLocation]
  );

  return (
    <AppContext.Provider value={contextProps}>{children}</AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
