import React from 'react';
import cn from 'classnames';
import { ISliceConfig } from '@livewire/sanity';
import * as styles from './styles.module.scss';

interface IProps {
  children: React.ReactNode;
  config?: ISliceConfig;
  className?: string;
}

interface ISliceConfigColors {
  color?: string;
  backgroundColor?: string;
}

const SliceConfig = ({ children, config, className }: IProps) => {
  const { backgroundColor, textColor, slicePadding } = config || {};
  const { paddingTop = `regular`, paddingBottom = `regular` } =
    slicePadding || {};

  const colorStyles: ISliceConfigColors = {};
  if (textColor?.hex) {
    colorStyles.color = textColor.hex;
  }
  if (backgroundColor?.hex) {
    colorStyles.backgroundColor = backgroundColor.hex;
  }

  return (
    <div
      style={colorStyles}
      className={cn(styles.container, className, {
        [styles.paddingTopSmall]: paddingTop === `small`,
        [styles.paddingTopNone]: paddingTop === `none`,
        [styles.paddingBottomSmall]: paddingBottom === `small`,
        [styles.paddingBottomNone]: paddingBottom === `none`
      })}
    >
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default SliceConfig;
