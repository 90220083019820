import React, { useState } from 'react';
import cn from 'classnames';
import { Grid, IntersectionAnimation } from '@livewire/common/components';
import {
  Card,
  CarouselNav,
  EmblaWrapper,
  MediaCard
} from '@livewire/website/src/components';
import { v4 as uuidv4 } from 'uuid';
import { useBreakpoints, useCarousel } from '@livewire/common/hooks';
import { ICardGrid } from '@livewire/sanity';
import { SliceConfig } from '@livewire/website/src/components';
import * as styles from './styles.module.scss';

interface IProps {
  data: ICardGrid;
}

const CardGrid = ({
  data: { sliceConfig, headingStyle, heading, mobileCarousel, cards }
}: IProps) => {
  const { emblaRef, scrollNext, scrollPrev, canPrev, canNext } = useCarousel({
    options: {
      align: `start`,
      loop: false,
      slidesToScroll: 1
    }
  });

  const [ids] = useState(cards?.[0] ? cards.map(() => uuidv4()) : []);

  const breakpoints = useBreakpoints();

  const animationStagger = 150;

  let showCarousel = false;

  if (breakpoints?.largeTablet) {
    showCarousel = cards?.length > 3;
  } else if (breakpoints?.tablet) {
    showCarousel = cards?.length > 3;
  } else {
    showCarousel = mobileCarousel;
  }

  return (
    <SliceConfig className={styles.container} config={sliceConfig}>
      {((heading && cards.length > 3) || showCarousel) && (
        <header className={styles.header}>
          <Grid>
            <div className={styles.heading}>
              <IntersectionAnimation>
                <h2 className={cn(`${headingStyle ? headingStyle : `h4`}`)}>
                  {heading}
                </h2>
              </IntersectionAnimation>
            </div>

            {showCarousel && (
              <div className={styles.carouselNav}>
                <IntersectionAnimation>
                  <CarouselNav
                    canNext={canNext}
                    canPrev={canPrev}
                    scrollNext={scrollNext}
                    scrollPrev={scrollPrev}
                  />
                </IntersectionAnimation>
              </div>
            )}
          </Grid>
        </header>
      )}

      {showCarousel && (
        <Grid className={styles.cards}>
          <IntersectionAnimation className={styles.carousel}>
            <EmblaWrapper emblaRef={emblaRef}>
              {cards?.[0] &&
                cards.map((card, index) => {
                  const { _type } = card || {};
                  const CardComponent =
                    _type === 'cardGrid.mediaCard' ? MediaCard : Card;

                  return (
                    <li
                      key={`card-carousel-${ids?.[index] ? ids[index] : index}`}
                      className={styles.carouselSlide}
                    >
                      <CardComponent carousel data={card} />
                    </li>
                  );
                })}
            </EmblaWrapper>
          </IntersectionAnimation>
        </Grid>
      )}

      {!showCarousel && (
        <Grid
          className={cn(styles.cards, {
            [styles.flex]: cards?.length <= 3,
            [styles.heading]: cards?.length <= 3 && heading
          })}
        >
          {heading && cards.length <= 3 && (
            <header
              className={cn(styles.header, {
                [styles.flexHeader]: cards?.length <= 3 && heading
              })}
            >
              <div className={styles.heading}>
                <IntersectionAnimation>
                  <h2 className={cn(`${headingStyle ? headingStyle : `h4`}`)}>
                    {heading}
                  </h2>
                </IntersectionAnimation>
              </div>
            </header>
          )}
          {cards?.[0] && (
            <div
              className={cn(styles.cardWrapper, {
                [styles.flex]: cards?.length <= 3
              })}
            >
              {cards.map((card, index) => {
                const { _type } = card || {};
                const CardComponent =
                  _type === 'cardGrid.mediaCard' ? MediaCard : Card;

                return (
                  <IntersectionAnimation
                    key={`card-grid-${ids?.[index] ? ids[index] : index}`}
                    delay={animationStagger * index}
                    className={cn(styles.cardContainer, {
                      [styles.flex]: cards?.length <= 3
                    })}
                  >
                    <CardComponent data={card} />
                  </IntersectionAnimation>
                );
              })}
            </div>
          )}
        </Grid>
      )}
    </SliceConfig>
  );
};

export default CardGrid;
