import React from 'react';
import cn from 'classnames';
import { SVG } from '@livewire/common/components';

import * as styles from './styles.module.scss';

interface ICarouselNav {
  canPrev: boolean;
  canNext: boolean;
  scrollPrev: () => void;
  scrollNext: () => void;
}

const CarouselNav = ({
  canPrev,
  canNext,
  scrollPrev,
  scrollNext
}: ICarouselNav) => {
  return (
    <nav className={styles.container}>
      <button
        type="button"
        aria-label="Previous"
        className={cn(styles.button, {
          [styles.disabled]: !canPrev
        })}
        onClick={() => {
          if (canPrev) {
            scrollPrev();
          }
        }}
      >
        <SVG svg="chevronLeft" />
      </button>

      <button
        type="button"
        aria-label="Next"
        className={cn(styles.button, {
          [styles.disabled]: !canNext
        })}
        onClick={() => {
          if (canNext) {
            scrollNext();
          }
        }}
      >
        <SVG svg="chevronRight" />
      </button>
    </nav>
  );
};

export default CarouselNav;
