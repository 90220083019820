import React, { FC } from 'react';
import * as Slices from '@livewire/website/src/slices';
import { ISlicePreview, ISlice, TPageType } from '@livewire/website/types';

interface IProps {
  data: any;
  id?: string;
  sliceIndex: number;
  pageSlug: string;
  pageType: TPageType;
  preview?: boolean;
}

const SanitySlice = ({
  data,
  id,
  sliceIndex,
  pageSlug,
  pageType,
  preview
}: IProps) => {
  const { _type: sliceType } = data;

  if (!sliceType) {
    console.error(
      `SanitySlice requires a valid _type, but none was received. Check the /templates/page file to ensure a valid slice type is being passed to the SanitySlice component.`
    );

    return null;
  }

  const strippedSliceName = sliceType.replace(`website.`, ``);

  const sliceName =
    strippedSliceName[0].toUpperCase() +
    strippedSliceName
      .slice(1, strippedSliceName.length)
      .replace(`Website.`, ``);

  const SliceSection = (Slices as any)?.[sliceName] as FC<ISlice>;

  const PreviewSliceSection = (Slices as any)?.[
    `Preview${sliceName}`
  ] as FC<ISlicePreview>;

  if (!SliceSection) {
    console.error(
      `Can't find Slice '${sliceName}', are you sure it has been configured in the slices directory?`
    );

    return null;
  }

  return (
    <section id={id} className={`slice-${sliceName}`}>
      {preview && PreviewSliceSection ? (
        <PreviewSliceSection
          data={data}
          sliceIndex={sliceIndex}
          pageSlug={pageSlug}
          pageType={pageType}
        />
      ) : (
        <SliceSection data={data} />
      )}
    </section>
  );
};

export default SanitySlice;
