// extracted by mini-css-extract-plugin
export var button = "styles-module--button--f60db";
export var container = "styles-module--container--ab005";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--b8190";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var swap = "styles-module--swap--d72b5";
export var tablet = "768px";
export var text = "styles-module--text--bcbe3";