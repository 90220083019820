import React, { useState } from 'react';
import cn from 'classnames';
import { SVG } from '@livewire/common/components';
import * as styles from './styles.module.scss';

const FormField = ({
  name,
  type,
  options,
  placeholder,
  defaultValue,
  handleChange,
  register,
  validation,
  hasError
}) => {
  const [showPassword, setShowPassword] = useState(false);

  if (type === 'textarea') {
    return (
      <textarea
        {...register(name, validation)}
        className={cn('b2', styles.textArea)}
        name={name}
        id={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
    );
  }
  if (type === 'select') {
    return (
      <select
        name={name}
        {...register(name, validation)}
        className={cn('b2', styles.select)}
        aria-labelledby={placeholder}
        defaultValue={defaultValue}
      >
        <option value={''}>{placeholder}</option>
        {options.map((option) => (
          <option value={option} defaultValue={defaultValue} key={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  if (type === 'radio' || type === 'checkbox') {
    return (
      <div className={`${type}-buttons`}>
        {options.map((option) => (
          <div key={option} className={`${type}-button`}>
            <input
              {...register(name, validation)}
              type={type}
              id={option}
              name={name}
              value={option}
              defaultValue={defaultValue}
              onChange={handleChange}
              className={styles.input}
            />
            {type === 'checkbox' && (
              <svg viewBox="0 0 21 21">
                <polyline points="5 10.75 8.5 14.25 16 6" />
              </svg>
            )}
            <label htmlFor={option}>{option}</label>
          </div>
        ))}
      </div>
    );
  }

  if (type === 'password') {
    const handleShowPassword = (e: React.SyntheticEvent) => {
      e.preventDefault();

      setShowPassword((prev) => !prev);
    };

    return (
      <div className={styles.passwordContainer}>
        <input
          {...register(name, {
            ...validation,
            onChange: (e: React.SyntheticEvent) => handleChange(e)
          })}
          className={cn('b2', styles.input, {
            [styles.error]: hasError
          })}
          type={showPassword ? 'text' : 'password'}
          name={name}
          id={name}
          placeholder={placeholder}
          autoComplete="off"
        />
        <button
          className={styles.passwordButton}
          onMouseDown={(e) => handleShowPassword(e)}
          type="button"
          tabIndex={-1}
        >
          <figure className={styles.passwordSvg}>
            <SVG svg={showPassword ? 'eyeHide' : 'eye'} />
          </figure>
        </button>
      </div>
    );
  }

  return (
    <input
      {...register(name, validation)}
      className={cn('b2', styles.input, { [styles.error]: hasError })}
      type={type}
      name={name}
      id={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
    />
  );
};

export default FormField;
