import React from 'react';

import * as styles from './styles.module.scss';

interface IEmblaCarousel {
  emblaRef: any;
  children: any;
}

const EmblaWrapper = ({ emblaRef, children }: IEmblaCarousel) => {
  return (
    <div ref={emblaRef} className={styles.container}>
      <ul className={styles.embla}>{children}</ul>
    </div>
  );
};

export default EmblaWrapper;
