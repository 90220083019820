import React from 'react';
import { Button, Grid, UniversalLink } from '@livewire/common/components';
import cn from 'classnames';
import { useApp } from '@livewire/website/src/hooks';
import { graphql, useStaticQuery } from 'gatsby';
import { IMenu } from '@livewire/sanity';

import * as styles from './styles.module.scss';

interface IProps {
  pathname: string;
}

const Menu = ({ pathname }: IProps) => {
  const settings: IMenu = useStaticQuery(graphql`
    query {
      sanitySettings {
        menu {
          links {
            _key
            ...UniversalLinkWebsiteFragment
          }

          ctaButton {
            ...UniversalLinkWebsiteFragment
          }
        }
      }
    }
  `)?.sanitySettings?.menu;

  const { isMenuOpen } = useApp();

  const menu = settings?.links;

  const button = settings?.ctaButton;

  return (
    <>
      <div className={cn(styles.container, { [styles.active]: isMenuOpen })}>
        <Grid className={styles.content}>
          <ul className={styles.links}>
            {menu.map((item) => {
              const { _key, link } = item || {};

              const slug = link?.[0]?.page?.slug?.current;

              const currentPage = slug === pathname;

              return (
                <li key={`mobile-nav-link-${_key}`}>
                  <UniversalLink
                    className={cn(styles.link, {
                      [styles.active]: currentPage
                    })}
                    link={item}
                    variant="tab"
                  />
                </li>
              );
            })}
          </ul>

          <div className={styles.contactButton}>
            <UniversalLink link={button} theme="lightOnDark" />
          </div>
        </Grid>
      </div>

      <div className={cn(styles.overlay, { [styles.active]: isMenuOpen })} />
    </>
  );
};

export default Menu;
