import React from 'react';
import { SlicePreview } from '@livewire/website/src/components';
import { ISlicePreview } from '@livewire/website/types';
import articleGridGroq from './queries.groq';
import ArticleGrid from './';

const PreviewArticleGrid = ({
  data,
  pageSlug,
  pageType,
  sliceIndex
}: ISlicePreview) => {
  return (
    <SlicePreview
      data={data}
      groqQuery={articleGridGroq}
      pageSlug={pageSlug}
      pageType={pageType}
      sliceComponent={ArticleGrid}
      sliceIndex={sliceIndex}
    />
  );
};

export default PreviewArticleGrid;
