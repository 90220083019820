import React from 'react';
import { SlicePreview } from '@livewire/website/src/components';
import { ISlicePreview } from '@livewire/website/types';
import flexibleArticleGroq from './queries.groq';
import FlexibleArticle from './';

const PreviewFlexibleArticle = ({
  data,
  pageSlug,
  pageType,
  sliceIndex
}: ISlicePreview) => {
  return (
    <SlicePreview
      data={data}
      groqQuery={flexibleArticleGroq}
      pageSlug={pageSlug}
      pageType={pageType}
      sliceComponent={FlexibleArticle}
      sliceIndex={sliceIndex}
    />
  );
};

export default PreviewFlexibleArticle;
