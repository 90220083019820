import React from 'react';
import cn from 'classnames';
import { AudioVideo } from '@livewire/website/src/components';
import {
  IntersectionAnimation,
  LayoutProvider
} from '@livewire/common/components';
import { useBreakpoints } from '@livewire/common/hooks';
import { Image, SliceConfig } from '@livewire/website/src/components';
import { IWebsiteFullWidthMedia } from '@livewire/sanity';

import * as styles from './styles.module.scss';

interface IProps {
  data: IWebsiteFullWidthMedia;
}

const FullWidthMedia = ({
  data: { sliceConfig, mediaWithCaption: media }
}: IProps) => {
  const { tablet } = useBreakpoints();

  const twoCol = media?.length > 1;

  return (
    <SliceConfig
      className={cn(styles.container, { [styles.twoCol]: twoCol })}
      config={sliceConfig}
    >
      <IntersectionAnimation>
        <LayoutProvider grid={tablet}>
          {media?.[0] &&
            media.map((item) => {
              const {
                _key,
                caption,
                subcaption,
                media: { image, video }
              } = item || {};

              return (
                <div
                  key={`full-width-media-item-${_key}`}
                  className={cn(styles.mediaContainer, {
                    [styles.twoCol]: twoCol
                  })}
                >
                  <figure
                    // ref={mediaRef}
                    className={cn(styles.media, {
                      [styles.twoCol]: twoCol
                    })}
                  >
                    {(video?.url && <AudioVideo data={video} />) || (
                      <>{image?.asset && <Image image={image} />}</>
                    )}
                  </figure>

                  {(caption || subcaption) && (
                    <div className={styles.captions}>
                      {caption && <p className="b1">{caption}</p>}

                      {subcaption && <p className="b3">{subcaption}</p>}
                    </div>
                  )}
                </div>
              );
            })}
        </LayoutProvider>
      </IntersectionAnimation>
    </SliceConfig>
  );
};

export default FullWidthMedia;
