import React, { Suspense, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import cn from 'classnames';
import { Canvas, useFrame } from '@react-three/fiber';
import { Grid } from '@livewire/common/components';
import { useScroll } from '@livewire/common/hooks';
import { Logo } from '@livewire/website/src/components';

import ContentBack from './ContentBack';
import ContentFront from './ContentFront';

import * as styles from './styles.module.scss';

const MagicMedia = ({ data }) => {
  const { frontImage, frontImageMobile, backImage, backImageMobile } =
    data || {};
  // ---------------------------------------------------------------------------
  // imports / hooks

  const { scrollY } = useScroll();

  // ---------------------------------------------------------------------------
  // ref / state

  const containerRef = useRef(null);
  const scrollArea = useRef();

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [scrollPercent, setScrollPercent] = useState(0);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!containerRef?.current || typeof window === `undefined`) {
      return;
    }

    const handleResize = () => {
      setDimensions({
        width: containerRef.current.getBoundingClientRect().width,
        height: containerRef.current.getBoundingClientRect().height
      });
    };

    window.addEventListener(`resize`, handleResize);

    setDimensions({
      width: containerRef.current.getBoundingClientRect().width,
      height: containerRef.current.getBoundingClientRect().height
    });

    return () => {
      window.removeEventListener(`resize`, handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(`dimensions: `, dimensions);
  }, [dimensions]);

  useEffect(() => {
    if (!containerRef?.current || typeof window === `undefined`) {
      return;
    }

    const adjustedScrollY =
      scrollY - containerRef.current.offsetTop + window.innerHeight;

    const containerHeight = containerRef.current.getBoundingClientRect().height;

    if (
      adjustedScrollY < 0 ||
      adjustedScrollY >
        containerRef.current.getBoundingClientRect().height + window.innerHeight
    ) {
      return;
    }

    const percent = adjustedScrollY / (containerHeight + window.innerHeight);

    setScrollPercent(percent);
  }, [containerRef, scrollY]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <div className={styles.container} ref={containerRef}>
      <div ref={scrollArea} className={styles.scrollArea}></div>

      <div className={styles.canvas}>
        <Canvas
          camera={{ position: [0, 0, 100], near: 0, far: 1000, zoom: 100 }}
          orthographic
          shadows
        >
          {/* <hemisphereLight intensity={1} groundColor="#CBFE00" /> */}

          {/* <spotLight
            position={[2, 5, 2]}
            color="#ffffff"
            intensity={2.5}
            shadow-mapSize-height={1024}
            shadow-mapSize-width={1024}
            shadow-camera-far={50}
            shadow-camera-left={-10}
            shadow-camera-right={10}
            shadow-camera-top={10}
            shadow-camera-bottom={-10}
            castShadow
          /> */}

          <Suspense fallback={null}>
            <ContentFront
              image={frontImage}
              imageMobile={frontImageMobile}
              dimensions={dimensions}
              scrollPercent={scrollPercent}
              scrollY={scrollY}
            />
            <ContentBack
              image={backImage}
              imageMobile={backImageMobile}
              dimensions={dimensions}
              scrollPercent={scrollPercent}
              scrollY={scrollY}
            />
          </Suspense>
        </Canvas>
      </div>
    </div>
  );
};

export default MagicMedia;
