import { useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';

interface IProps {
  slides: any[];
  options: any;
  plugins?: any[];
}

export const useCarousel = ({ options, plugins = [] }: IProps) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  const [emblaRef, emblaApi] = useEmblaCarousel(options, plugins);

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [current, setCurrent] = useState(0);
  const [canNext, setCanNext] = useState(true);
  const [canPrev, setCanPrev] = useState(false);

  // ---------------------------------------------------------------------------
  // methods

  const scrollPrev = () => {
    if (emblaApi) {
      emblaApi.scrollPrev();
    }
  };

  const scrollNext = () => {
    if (emblaApi) {
      emblaApi.scrollNext();
    }
  };

  const reset = () => {
    if (!emblaApi) {
      return;
    }

    emblaApi.reInit(options);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on(`select`, () => setCurrent(emblaApi.selectedScrollSnap()));
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    setCanNext(emblaApi.canScrollNext());
    setCanPrev(emblaApi.canScrollPrev());
  }, [emblaApi, current]);

  // ---------------------------------------------------------------------------

  return {
    emblaRef,
    emblaApi,
    reset,
    current,
    setCurrent,
    scrollPrev,
    scrollNext,
    canPrev,
    canNext
  };
};

export default useCarousel;
