import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { useLoader } from '@react-three/fiber';
import { Mask, useMask } from '@react-three/drei';
import { useBreakpoints } from '@livewire/common/hooks';
import * as THREE from 'three';

//
// todo: Fix TS warnings
// todo: DRY with ContentBack
const MaskedImage = ({ image, imageMobile, scrollPercent, largeTablet }) => {
  const stencil = useMask(1, false);
  const maskRef = useRef();
  const groupRef = useRef();

  const mobileImage = imageMobile ? imageMobile : image;

  const IMG_SRC = largeTablet ? image?.asset?.url : mobileImage?.asset?.url;
  const texture = useLoader(THREE.TextureLoader, IMG_SRC);

  useEffect(() => {
    if (!groupRef?.current) {
      return;
    }

    gsap.killTweensOf(groupRef.current.position);

    gsap.to(groupRef.current.position, {
      y: -1 + scrollPercent,
      duration: 0
    });
  }, [groupRef, scrollPercent]);

  if (typeof window === `undefined`) {
    return null;
  }

  let imageWidth = 24;

  if (typeof window !== `undefined`) {
    imageWidth = window.innerWidth * 0.01;
  }

  const aspectRatio = texture.image.height / texture.image.width;
  const imageHeight = imageWidth * aspectRatio;

  return (
    <group ref={groupRef}>
      <mesh ref={maskRef} scale={[1, 1, 0]}>
        <planeGeometry attach="geometry" args={[imageWidth, imageHeight]} />
        <meshBasicMaterial attach="material" map={texture} {...stencil} />
      </mesh>
    </group>
  );
};

const ContentFront = ({ image, imageMobile, dimensions, scrollPercent }) => {
  if (!image?.asset?.url) return;

  const { largeTablet } = useBreakpoints();

  const groupRef = useRef();
  const maskRef = useRef();

  const BASE_SCALE = [dimensions?.width * 0.01, dimensions?.height * 0.007, 1];
  const SCROLL_START = 0.3;
  const SCROLL_END = 0.5;
  const SCROLL_SPEED = 8;

  const FINAL_ROTATE_X = 0.23;
  const FINAL_ROTATE_Y = Math.PI / -2 - 0.05;

  useEffect(() => {
    if (!maskRef?.current) {
      return;
    }

    gsap.killTweensOf(maskRef.current.rotation);

    if (scrollPercent < SCROLL_START) {
      gsap.to(maskRef.current.rotation, {
        x: 0,
        y: 0,
        duration: 0
      });

      return;
    } else if (scrollPercent > SCROLL_END) {
      gsap.to(maskRef.current.rotation, {
        x: FINAL_ROTATE_X,
        y: FINAL_ROTATE_Y,
        duration: 0
      });

      return;
    }

    const adjustedScrollPercent = scrollPercent - SCROLL_START;

    const rotationX = adjustedScrollPercent;
    const rotationY = -adjustedScrollPercent * SCROLL_SPEED;

    gsap.to(maskRef.current.rotation, {
      x: rotationX,
      y: rotationY,
      duration: 0
    });
  }, [maskRef, scrollPercent]);

  //

  if (typeof window === `undefined`) {
    return null;
  }

  // todo: tablet, mobile

  return (
    <group ref={groupRef}>
      <group ref={maskRef} scale={BASE_SCALE}>
        <Mask id={1} colorWrite={true} depthWrite={false}>
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial attach="material" color="#191919" />
        </Mask>
      </group>

      <MaskedImage
        image={image}
        imageMobile={imageMobile}
        scrollPercent={scrollPercent}
        largeTablet={largeTablet}
      />
    </group>
  );
};

export default ContentFront;
