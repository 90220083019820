// extracted by mini-css-extract-plugin
export var container = "styles-module--container--c1955";
export var content = "styles-module--content--9558e";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var paddingBottomNone = "styles-module--paddingBottomNone--333cb";
export var paddingBottomSmall = "styles-module--paddingBottomSmall--a3014";
export var paddingTopNone = "styles-module--paddingTopNone--3eb93";
export var paddingTopSmall = "styles-module--paddingTopSmall--5264d";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";