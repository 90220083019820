// extracted by mini-css-extract-plugin
export var body = "styles-module--body--32f72";
export var container = "styles-module--container--8ea9e";
export var copyright = "styles-module--copyright--85a9a";
export var desktop = "1340px";
export var giant = "2200px";
export var internalLinks = "styles-module--internalLinks--436e1";
export var lamLink = "styles-module--lamLink--b0ca4";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var legal = "styles-module--legal--b5fb9";
export var lockup = "styles-module--lockup--a8962";
export var logo = "styles-module--logo--be6d4";
export var logoLink = "styles-module--logoLink--83592";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var socialLinks = "styles-module--socialLinks--bdf61";
export var tablet = "768px";