// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--e4b49";
export var appearDown = "styles-module--appear-down--ca718";
export var appearLeft = "styles-module--appear-left--b7131";
export var appearRight = "styles-module--appear-right--cf866";
export var appearUp = "styles-module--appear-up--dfb97";
export var container = "styles-module--container--1a170";
export var content = "styles-module--content--bfa1e";
export var desktop = "1340px";
export var giant = "2200px";
export var heading = "styles-module--heading--cc2e3";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--b772b";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";