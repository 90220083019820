import React, { useRef } from 'react';
import cn from 'classnames';
import gsap from 'gsap';
import { Grid } from '@livewire/common/components';
import { useBreakpoints, useIntersectionScroll } from '@livewire/common/hooks';
import { SliceConfig } from '@livewire/website/src/components';
import { IWebsiteMedia } from '@livewire/sanity';
import * as styles from './styles.module.scss';

interface IProps {
  data: IWebsiteMedia;
}

const Media = ({
  data: { sliceConfig, heading, subheading, media }
}: IProps) => {
  const mediaRef = useRef();

  const { largeTablet } = useBreakpoints();

  const { ref } = useIntersectionScroll((sectionTop) => {
    const y = parseInt(-sectionTop / (largeTablet ? 10 : 15));

    gsap.to(mediaRef.current, {
      duration: 0,
      y
    });
  });

  return (
    <SliceConfig config={sliceConfig}>
      <section ref={ref} className={styles.container}>
        <figure ref={mediaRef} className={styles.media}>
          {media?.video?.url && (
            <video autoPlay muted loop playsInline>
              <source src={media.video.url} type="video/mp4" />
            </video>
          )}

          {!media?.video?.url && media?.image?.asset && (
            <img src={media.image.asset.url} alt={media.image.alt} />
          )}
        </figure>

        <Grid>
          <article className={styles.content}>
            <h2 className={cn(styles.heading, `h2`)}>{heading}</h2>
            <h3 className="b1">{subheading}</h3>
          </article>
        </Grid>
      </section>
    </SliceConfig>
  );
};

export default Media;
