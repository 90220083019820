import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { SVG } from '@livewire/common/components';

import * as styles from './styles.module.scss';

const HoverLogo = ({ color = `#ffffff`, small }) => {
  let row = -1;

  const finalState = [
    [0, 1, 1, 0],
    [1, 0, 1, 1],
    [1, 0, 0, 1],
    [1, 1, 1, 0]
  ];
  const timeline = [
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [1, 0, 0, 0],
      [1, 1, 0, 0]
    ],
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [1, 0, 0, 0],
      [1, 1, 0, 0]
    ],
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [1, 0, 1, 0],
      [1, 1, 0, 0]
    ],
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [1, 0, 1, 0],
      [1, 1, 0, 0]
    ],
    [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [1, 0, 1, 0],
      [1, 1, 0, 0]
    ],
    [
      [1, 0, 0, 1],
      [0, 0, 1, 1],
      [0, 1, 1, 0],
      [1, 0, 0, 0]
    ],
    [
      [1, 0, 0, 1],
      [0, 0, 1, 1],
      [0, 1, 1, 0],
      [1, 0, 0, 0]
    ],
    [
      [1, 0, 0, 1],
      [0, 0, 1, 1],
      [0, 1, 1, 0],
      [1, 0, 0, 0]
    ],
    [
      [1, 0, 1, 1],
      [1, 0, 1, 1],
      [0, 1, 1, 0],
      [1, 0, 0, 1]
    ],
    [
      [1, 0, 1, 1],
      [1, 0, 1, 1],
      [0, 0, 1, 0],
      [1, 0, 0, 1]
    ],
    [
      [1, 0, 1, 1],
      [1, 0, 1, 1],
      [0, 0, 1, 0],
      [1, 0, 0, 1]
    ],
    [
      [0, 0, 0, 1],
      [1, 0, 1, 0],
      [0, 0, 1, 0],
      [1, 0, 0, 1]
    ],
    [
      [0, 0, 0, 1],
      [1, 0, 1, 0],
      [0, 0, 0, 0],
      [1, 0, 1, 1]
    ],
    [
      [0, 0, 0, 1],
      [1, 0, 1, 0],
      [0, 0, 0, 0],
      [1, 0, 1, 1]
    ],
    [
      [0, 0, 0, 0],
      [1, 0, 1, 0],
      [0, 0, 0, 0],
      [1, 1, 1, 1]
    ],
    [
      [1, 0, 0, 0],
      [1, 0, 0, 0],
      [0, 0, 0, 0],
      [1, 1, 1, 1]
    ],
    [
      [1, 0, 0, 0],
      [1, 0, 0, 0],
      [0, 0, 0, 0],
      [1, 1, 1, 1]
    ],
    [
      [1, 0, 0, 0],
      [0, 0, 0, 0],
      [1, 0, 0, 0],
      [1, 1, 1, 1]
    ],
    [
      [1, 0, 0, 0],
      [0, 0, 0, 0],
      [1, 0, 0, 0],
      [1, 1, 1, 1]
    ],
    [
      [1, 0, 1, 0],
      [0, 0, 0, 0],
      [1, 0, 0, 1],
      [0, 1, 1, 1]
    ],
    [
      [1, 0, 1, 0],
      [0, 0, 0, 0],
      [1, 0, 0, 1],
      [0, 1, 1, 0]
    ],
    [
      [1, 0, 1, 0],
      [0, 0, 0, 0],
      [1, 0, 0, 1],
      [0, 1, 1, 0]
    ],
    [
      [1, 1, 1, 0],
      [0, 0, 0, 1],
      [1, 0, 0, 1],
      [0, 1, 1, 0]
    ],
    [
      [1, 1, 1, 0],
      [0, 0, 0, 1],
      [1, 0, 0, 1],
      [0, 1, 1, 0]
    ],
    [
      [1, 1, 1, 0],
      [0, 0, 1, 1],
      [1, 0, 0, 1],
      [0, 1, 1, 0]
    ],
    [
      [0, 1, 1, 0],
      [0, 0, 1, 1],
      [1, 0, 0, 1],
      [0, 1, 1, 0]
    ],
    [
      [0, 1, 1, 0],
      [0, 0, 1, 1],
      [1, 0, 0, 1],
      [0, 1, 1, 0]
    ],
    finalState
  ];

  // ---------------------------------------------------------------------------
  // ref / state

  const [activeMatrixIndex, setActiveMatrixIndex] = useState(null);

  const [hovered, setHovered] = useState(false);
  const [animationTimeouts, setAnimationTimeouts] = useState([]);

  // ---------------------------------------------------------------------------
  // methods

  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseOut = () => {
    setHovered(false);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    const animate = () => {
      const timeouts: ((prevState: never[]) => never[]) | NodeJS.Timeout[] = [];
      let frame = 0;

      timeline.forEach((matrix, index) => {
        const timeout = setTimeout(() => {
          setActiveMatrixIndex(index);
        }, frame * 30);

        timeouts.push(timeout);
        frame += 1;
      });

      setAnimationTimeouts(timeouts);
    };

    if (!hovered) {
      animationTimeouts.forEach((timeout) => clearTimeout(timeout));
      setActiveMatrixIndex(timeline?.length - 1);
    } else {
      animate();
    }

    return () => {
      animationTimeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, [hovered]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <div
      className={styles.container}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <SVG svg="wordmark" />

      <div className={styles.logo}>
        <div className={styles.matrixContainer}>
          <div className={styles.matrix}>
            {Array(16)
              .fill(null)
              .map((_, index) => {
                const node = index % 4;

                if (node === 0) {
                  row += 1;
                }

                const active =
                  timeline?.[activeMatrixIndex]?.[row]?.[node] === 1;

                return (
                  <div key={`logo-node-${index}`} className={cn(styles.node)}>
                    <div
                      className={cn(styles.background, {
                        [styles.active]: active,
                        [styles.small]: small
                      })}
                      style={{ background: active ? color : `transparent` }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoverLogo;
