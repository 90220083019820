// extracted by mini-css-extract-plugin
export var cardContainer = "styles-module--cardContainer--c4fd8";
export var carousel = "styles-module--carousel--86b05";
export var carouselNav = "styles-module--carouselNav--b95f7";
export var carouselSlide = "styles-module--carouselSlide--17c05";
export var container = "styles-module--container--f781a";
export var desktop = "1340px";
export var filterButtons = "styles-module--filterButtons--aeea8";
export var filterSelect = "styles-module--filterSelect--ed8c4";
export var giant = "2200px";
export var grid = "styles-module--grid--700e5";
export var header = "styles-module--header--16d39";
export var heading = "styles-module--heading--9e31e";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var loadMore = "styles-module--loadMore--f4652";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";