import React, { useEffect, useState } from 'react';
import {
  IntersectionAnimation,
  LayoutProvider,
  UniversalLink
} from '@livewire/common/components';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';
import {
  AudioVideo,
  Image,
  SliceConfig,
  Tag
} from '@livewire/website/src/components';
import { IFeature, IVariableMedia } from '@livewire/sanity';
import * as styles from './styles.module.scss';

export interface IProps {
  data: IFeature;
}

const Feature = ({ data: { sliceConfig, blocks } }: IProps) => {
  const [isFullWidth, setIsFullWidth] = useState(false);

  const getMediaJsx = (media: IVariableMedia) => {
    const { image, video } = media || {};

    let jsx = null;

    if (video?.url) {
      jsx = <AudioVideo className={styles.video} data={media.video} />;
    } else if (image) {
      jsx = <Image image={image} />;
    }

    return jsx;
  };

  useEffect(() => {
    if (!blocks?.[0]) return;

    const containsLargeHeading = blocks.some(
      ({ headingStyle }) => headingStyle === 'd3'
    );

    setIsFullWidth(containsLargeHeading);
  }, [blocks]);

  return (
    <SliceConfig className={styles.container} config={sliceConfig}>
      <LayoutProvider grid>
        <div
          className={cn(styles.content, { [styles.fullWidth]: isFullWidth })}
        >
          {blocks?.[0] &&
            blocks.map(
              ({
                tags,
                headingStyle,
                heading,
                subheading,
                body,
                media,
                mediaPosition,
                buttons
              }) => {
                const { buttons: buttonGroup } = buttons || {};

                const hasBody = body || buttonGroup?.[0];

                return (
                  <article
                    className={styles.block}
                    key={`feature-block-${uuidv4()}`}
                  >
                    {media && (
                      <IntersectionAnimation
                        className={cn(styles.mediaContainer, {
                          [styles.bottom]: mediaPosition === 'bottom'
                        })}
                      >
                        <figure className={styles.media}>
                          {getMediaJsx(media)}
                        </figure>
                      </IntersectionAnimation>
                    )}

                    <div className={styles.text}>
                      <header
                        className={cn(styles.header, {
                          [styles.fullWidth]: blocks.length > 1 || !hasBody
                        })}
                      >
                        {tags?.[0] && (
                          <IntersectionAnimation>
                            <ul className={styles.tags}>
                              {tags.map((tag) => {
                                return (
                                  <li key={`feature-block-tag-${uuidv4()}`}>
                                    <Tag variant="outline">{tag}</Tag>
                                  </li>
                                );
                              })}
                            </ul>
                          </IntersectionAnimation>
                        )}

                        {heading && (
                          <IntersectionAnimation>
                            <h1 className={headingStyle ? headingStyle : 'h3'}>
                              {heading}
                            </h1>
                          </IntersectionAnimation>
                        )}

                        {subheading && (
                          <IntersectionAnimation>
                            <h3 className="h5">{subheading}</h3>
                          </IntersectionAnimation>
                        )}
                      </header>

                      {hasBody && (
                        <div
                          className={cn('b1', styles.body, {
                            [styles.fullWidth]: blocks.length > 1
                          })}
                        >
                          <IntersectionAnimation>
                            <p>{body}</p>
                          </IntersectionAnimation>

                          <div className={styles.buttonContainer}>
                            {buttonGroup?.map((button, index) => {
                              const animationStagger = 100;
                              return (
                                <IntersectionAnimation
                                  key={`feature-button-${index}`}
                                  delay={animationStagger * index}
                                >
                                  <UniversalLink
                                    className={styles.button}
                                    link={button}
                                    theme="lightOnDark"
                                    variant={
                                      index === 0 ? 'primary' : 'secondary'
                                    }
                                  />
                                </IntersectionAnimation>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </article>
                );
              }
            )}
        </div>
      </LayoutProvider>
    </SliceConfig>
  );
};

export default Feature;
