import React from 'react';
import cn from 'classnames';
import { LayoutProvider, SVG, Form } from '@livewire/common/components';

import * as styles from './styles.module.scss';

interface IProps {
  password: string;
  handleSubmitPassword: (e: React.FormEvent<HTMLFormElement>) => void;
  passwordInputValue: string;
  setPasswordInputValue: (value: React.SetStateAction<string>) => void;
}

const PasswordForm = ({
  password,
  handleSubmitPassword,
  setPasswordInputValue
}: IProps) => {
  return (
    <div className={styles.container}>
      <LayoutProvider className={styles.grid} grid>
        <div className={styles.content}>
          <div className={styles.body}>
            <figure className={styles.svg}>
              <SVG svg="lock" />
            </figure>
            <h1 className={cn('d3', styles.heading)}>Access Restricted</h1>
            <p className={cn('b1', styles.text)}>
              Enter password to access this page.
            </p>
          </div>

          <div className={styles.form}>
            <Form
              type="password"
              password={{
                password: password,
                handleChange: (e: React.SyntheticEvent) =>
                  setPasswordInputValue(e.target.value)
              }}
              onSubmit={handleSubmitPassword}
              submitButton={{
                defaultText: 'Unlock',
                submissionText: 'Unlocking',
                hideIcon: true
              }}
              reValidateMode="onSubmit"
            />
          </div>
        </div>
      </LayoutProvider>
    </div>
  );
};

export default PasswordForm;
