import React from 'react';
import cn from 'classnames';
import { Image } from '@livewire/website/src/components';
import { IAltImage } from '@livewire/sanity';
import * as styles from './styles.module.scss';

interface IArticleCard {
  data: {
    heading: string;
    image: IAltImage;
    video?: {
      url: string;
    };
    excerpt: string;
  };
  carousel?: boolean;
}

const ArticleCard = ({
  data: { heading, image, excerpt, video },
  carousel
}: IArticleCard) => {
  return (
    <article className={cn(styles.container, { [styles.carousel]: carousel })}>
      <figure className={cn(styles.media, { [styles.carousel]: carousel })}>
        {(video?.url && (
          <video autoPlay muted loop playsInline>
            <source src={video.url} type="video/mp4" />
          </video>
        )) || (
          <Image image={image} />
          // <>
          //   {image?.asset?.url && image?.asset && (
          //     <img src={image.asset.url} alt={image.alt || 'Livewire'} />
          //   )}
          // </>
        )}
      </figure>

      <div className={cn(styles.text, { [styles.carousel]: carousel })}>
        <h3 className={cn(styles.heading, `b1`)}>{heading}</h3>

        {excerpt && <p className={cn(styles.excerpt, `b3`)}>{excerpt}</p>}
      </div>
    </article>
  );
};

export default ArticleCard;
