import React from 'react';
import { SlicePreview } from '@livewire/website/src/components';
import { ISlicePreview } from '@livewire/website/types';
import cardGridGroq from './queries.groq';
import CardGrid from './';

const PreviewCardGrid = ({
  data,
  pageSlug,
  pageType,
  sliceIndex
}: ISlicePreview) => {
  return (
    <SlicePreview
      data={data}
      groqQuery={cardGridGroq}
      pageSlug={pageSlug}
      pageType={pageType}
      sliceComponent={CardGrid}
      sliceIndex={sliceIndex}
    />
  );
};

export default PreviewCardGrid;
