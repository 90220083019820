import React from 'react';
import { SlicePreview } from '@livewire/website/src/components';
import { ISlicePreview } from '@livewire/website/types';
import magicMediaGroq from './queries.groq';
import MagicMedia from './';

const PreviewMagicMedia = ({
  data,
  pageSlug,
  pageType,
  sliceIndex
}: ISlicePreview) => {
  return (
    <SlicePreview
      data={data}
      groqQuery={magicMediaGroq}
      pageSlug={pageSlug}
      pageType={pageType}
      sliceComponent={MagicMedia}
      sliceIndex={sliceIndex}
    />
  );
};

export default PreviewMagicMedia;
