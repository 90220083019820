import React from 'react';
import cn from 'classnames';
import {
  IntersectionAnimation,
  LayoutProvider,
  UniversalLink
} from '@livewire/common/components';
import { IWebsiteFlexibleArticle, IVariableMedia } from '@livewire/sanity';
import {
  AudioVideo,
  Image,
  SliceConfig
} from '@livewire/website/src/components';

import * as styles from './styles.module.scss';

interface IProps {
  data: IWebsiteFlexibleArticle;
}

const FlexibleArticle = ({ data }: IProps) => {
  const {
    sliceConfig,
    media,
    mediaAlignment,
    subheading,
    heading,
    body,
    buttons
  } = data || {};
  const { buttons: buttonGroup } = buttons || {};
  const { media: mediaData, caption, subcaption } = media || {};

  const mediaRight = mediaAlignment === 'right';

  const animationStagger = 100;

  const getMediaJsx = (media: IVariableMedia) => {
    const { image, video } = media || {};
    let jsx = <></>;

    if (video) {
      jsx = <AudioVideo data={video} />;
    } else if (image) {
      jsx = <Image image={image} />;
    }

    return jsx;
  };

  return (
    <SliceConfig className={styles.container} config={sliceConfig}>
      <LayoutProvider gridClassName={styles.grid} grid>
        <div className={cn(styles.content, { [styles.left]: mediaRight })}>
          <article className={styles.text}>
            {subheading && (
              <IntersectionAnimation>
                <p className="caption">{subheading}</p>
              </IntersectionAnimation>
            )}
            {heading && (
              <IntersectionAnimation>
                <h2 className="h2">{heading}</h2>
              </IntersectionAnimation>
            )}
            {body && (
              <IntersectionAnimation>
                <p className={cn('b2', styles.body)}>{body}</p>
              </IntersectionAnimation>
            )}
          </article>

          {buttonGroup?.[0] && (
            <div className={styles.buttons}>
              {buttonGroup?.map((button, index) => {
                return (
                  <IntersectionAnimation
                    key={`flexible-article-button-${button?._key}`}
                    delay={animationStagger * index}
                  >
                    <UniversalLink
                      className={styles.button}
                      link={button}
                      theme="lightOnDark"
                      variant={index === 0 ? 'primary' : 'secondary'}
                    />
                  </IntersectionAnimation>
                );
              })}
            </div>
          )}
        </div>
        <div
          className={cn(styles.mediaContainer, { [styles.right]: mediaRight })}
        >
          {caption ||
            (subcaption && (
              <div className={styles.captions}>
                {caption && (
                  <IntersectionAnimation>
                    <p className={cn('b1', styles.caption)}>{caption}</p>
                  </IntersectionAnimation>
                )}
                {subcaption && (
                  <IntersectionAnimation>
                    <p className={cn('b3', styles.subcaption)}>{subcaption}</p>
                  </IntersectionAnimation>
                )}
              </div>
            ))}

          <IntersectionAnimation>
            <figure
              className={cn(styles.media, {
                [styles.square]: !caption && !subcaption
              })}
            >
              {getMediaJsx(mediaData)}
            </figure>
          </IntersectionAnimation>
        </div>
      </LayoutProvider>
    </SliceConfig>
  );
};

export default FlexibleArticle;
