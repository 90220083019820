// extracted by mini-css-extract-plugin
export var cardContainer = "styles-module--cardContainer--04552";
export var cardWrapper = "styles-module--cardWrapper--fc5e6";
export var cards = "styles-module--cards--4a4c7";
export var carousel = "styles-module--carousel--a2311";
export var carouselNav = "styles-module--carouselNav--4b079";
export var carouselSlide = "styles-module--carouselSlide--aa19e";
export var container = "styles-module--container--e139e";
export var desktop = "1340px";
export var flex = "styles-module--flex--7dd43";
export var flexHeader = "styles-module--flexHeader--45a76";
export var giant = "2200px";
export var header = "styles-module--header--810c5";
export var heading = "styles-module--heading--9f275";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";