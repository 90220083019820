import React, { useEffect } from 'react';
import cn from 'classnames';
import { graphql, Link, useStaticQuery } from 'gatsby';
import {
  Button,
  Grid,
  IntersectionAnimation
} from '@livewire/common/components';
import { useBreakpoints, useCarousel } from '@livewire/common/hooks';
import {
  ArticleCard,
  CarouselNav,
  EmblaWrapper,
  SliceConfig
} from '@livewire/website/src/components';

import * as styles from './styles.module.scss';
import { IArticleGrid } from '@livewire/sanity';

const SORTS = [
  {
    label: `Alphabetical`,
    value: `alphabetical`
  },
  {
    label: `Created Date`,
    value: `createdDate`
  }
];

const FILTERS = [
  {
    label: `Show All`,
    value: `all`
  },
  {
    label: `Case Studies`,
    value: `caseStudies`
  },
  {
    label: `News`,
    value: `news`
  }
];

interface IProps {
  data: IArticleGrid;
}

const ArticleGrid = ({
  data: {
    sliceConfig,
    headingStyle,
    heading,
    mobileCarousel,
    showAll,
    showAllCaseStudies,
    showAllNewsArticles,
    articles
  }
}: IProps) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  const breakpoints = useBreakpoints();

  const { emblaRef, scrollNext, scrollPrev, canPrev, canNext } = useCarousel({
    options: {
      align: `start`,
      loop: false,
      slidesToScroll: 1
    }
  });

  const allArticleData = useStaticQuery(graphql`
    query articleGridQuery {
      allSanityWebsiteCaseStudy {
        nodes {
          _type
          title

          slug {
            current
          }

          publishDate

          excerpt
          cardImage {
            image {
              asset {
                url
              }
            }

            video {
              url
            }
          }
        }
      }
      allSanityWebsiteNewsArticle {
        nodes {
          _type
          title

          slug {
            current
          }

          publishDate

          excerpt
          cardImage {
            image {
              asset {
                url
              }
            }

            video {
              url
            }
          }
        }
      }
    }
  `);

  const allArticles = allArticleData.allSanityWebsiteCaseStudy.nodes.concat(
    allArticleData.allSanityWebsiteNewsArticle.nodes
  );

  // ---------------------------------------------------------------------------
  // variables

  const automatic = showAll || showAllCaseStudies || showAllNewsArticles;

  const animationStagger = 150;

  let showCarousel = false;

  if (!showAll) {
    if (breakpoints?.largeTablet || breakpoints?.tablet) {
      showCarousel = articles?.length > 3;
    } else {
      showCarousel = mobileCarousel;
    }
  }

  // ---------------------------------------------------------------------------
  // ref / state

  let defaultFilter = ``;

  if (showAllCaseStudies) {
    defaultFilter = `caseStudies`;
  } else if (showAllNewsArticles) {
    defaultFilter = `news`;
  }

  const [filter, setFilter] = React.useState(defaultFilter);
  const [sort, setSort] = React.useState(`createdDate`);

  const [currentPage, setCurrentPage] = React.useState(1);
  const articlesPerPage = 6;

  const [visibleArticles, setVisibleArticles] = React.useState(
    showAll ? allArticles.slice(0, articlesPerPage) : articles
  );

  // ---------------------------------------------------------------------------
  // methods

  const getFilteredArticles = () => {
    let filteredArticles = JSON.parse(JSON.stringify(allArticles));

    if (filter !== `all`) {
      switch (filter) {
        case `caseStudies`:
          filteredArticles = filteredArticles.filter(
            (article) => article?._type === `website.caseStudy`
          );

          break;

        case `news`:
          filteredArticles = filteredArticles.filter(
            (article) => article?._type === `website.newsArticle`
          );

          break;

        default:
          break;
      }
    }

    switch (sort) {
      case `alphabetical`:
        filteredArticles.sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          }

          if (a.title > b.title) {
            return 1;
          }

          return 0;
        });

        break;

      case `createdDate`:
        filteredArticles.sort((a, b) => {
          if (a.publishDate < b.publishDate) {
            return 1;
          }

          if (a.publishDate > b.publishDate) {
            return -1;
          }

          return 0;
        });

        break;

      default:
        break;
    }

    return filteredArticles;
  };

  const paginate = () => {
    const nextPage = currentPage + 1;
    const startIndex = currentPage * articlesPerPage;
    const endIndex = nextPage * articlesPerPage;

    const newVisibleArticles = getFilteredArticles().slice(
      startIndex,
      endIndex
    );

    setVisibleArticles([...visibleArticles, ...newVisibleArticles]);
    setCurrentPage(nextPage);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!automatic) {
      return;
    }

    setCurrentPage(1);

    const filteredArticles = getFilteredArticles();

    setVisibleArticles(filteredArticles.slice(0, articlesPerPage));
  }, [filter, sort]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <SliceConfig className={styles.container} config={sliceConfig}>
      <header className={styles.header}>
        <Grid className={styles.grid}>
          {heading && (
            <div className={styles.heading}>
              <IntersectionAnimation>
                <h2 className={cn(`${headingStyle ? headingStyle : `h4`}`)}>
                  {heading}
                </h2>
              </IntersectionAnimation>
            </div>
          )}

          {!automatic && showCarousel && (
            <div className={styles.carouselNav}>
              <IntersectionAnimation>
                <CarouselNav
                  canNext={canNext}
                  canPrev={canPrev}
                  scrollNext={scrollNext}
                  scrollPrev={scrollPrev}
                />
              </IntersectionAnimation>
            </div>
          )}

          {/* todo: remove commented styling from .filterButtons when re-enabling sort by */}
          {automatic && (
            <div className={styles.filterButtons}>
              {showAll && (
                <select
                  className={styles.filterSelect}
                  onChange={(e) => {
                    setFilter(e.currentTarget.value);
                  }}
                  defaultValue={`Filter by:`}
                >
                  <option disabled>Filter by:</option>

                  {FILTERS.map((filter) => (
                    <option
                      key={`article-grid-filter-${filter.value}`}
                      value={filter.value}
                    >
                      {filter.label}
                    </option>
                  ))}
                </select>
              )}

              {/* <select
                className={styles.filterSelect}
                onChange={(e) => {
                  // setSort(e.currentTarget.value);
                }}
                defaultValue={`Sort by:`}
              >
                <option disabled>Sort by:</option>

                {SORTS.map((sort) => (
                  <option
                    key={`article-grid-sort-${sort.value}`}
                    value={sort.value}
                  >
                    {sort.label}
                  </option>
                ))}
              </select> */}
            </div>
          )}
        </Grid>
      </header>

      {showCarousel && (
        <Grid>
          <div className={styles.carousel}>
            <IntersectionAnimation>
              <EmblaWrapper emblaRef={emblaRef}>
                {articles.map((article, index) => {
                  let prefix = ``;

                  switch (article?._type) {
                    case `website.caseStudy`:
                      prefix = `/case-studies/`;
                      break;
                    case `website.newsArticle`:
                      prefix = `/news/`;
                      break;
                    default:
                      break;
                  }

                  const jsx = (
                    <ArticleCard
                      carousel
                      data={{
                        heading: article?.title || ``,
                        image: article?.cardImage?.image,
                        excerpt: article?.excerpt || null
                      }}
                    />
                  );

                  return (
                    <li
                      key={`card-carousel-${article?.title || index}`}
                      className={styles.carouselSlide}
                    >
                      {article?.slug?.current && (
                        <Link to={`${prefix}${article?.slug?.current}`}>
                          {jsx}
                        </Link>
                      )}

                      {!article?.slug?.current && jsx}
                    </li>
                  );
                })}
              </EmblaWrapper>
            </IntersectionAnimation>
          </div>
        </Grid>
      )}

      {!showCarousel && (
        <Grid className={styles.grid}>
          {visibleArticles?.[0] &&
            visibleArticles.map((article, index) => {
              let prefix = ``;

              switch (article?._type) {
                case `website.caseStudy`:
                  prefix = `/case-studies/`;
                  break;
                case `website.newsArticle`:
                  prefix = `/news/`;
                  break;
                default:
                  break;
              }

              return (
                <IntersectionAnimation
                  delay={animationStagger * index}
                  key={`article-grid-${article?.title || index}`}
                  className={styles.cardContainer}
                >
                  <Link to={`${prefix}${article?.slug?.current}`}>
                    <ArticleCard
                      data={{
                        heading: article?.title || ``,
                        image: article?.cardImage?.image,
                        excerpt: article?.excerpt || null,
                        video: article?.cardImage?.video || {}
                      }}
                    />
                  </Link>
                </IntersectionAnimation>
              );
            })}

          {automatic &&
            visibleArticles?.[0] &&
            visibleArticles?.length !== getFilteredArticles()?.length && (
              <IntersectionAnimation className={styles.loadMore}>
                <Button
                  color="neoteric"
                  variant={`primary`}
                  theme="lightOnDark"
                  onClick={() => {
                    paginate();
                  }}
                >
                  Load More
                </Button>
              </IntersectionAnimation>
            )}
        </Grid>
      )}
    </SliceConfig>
  );
};

export default ArticleGrid;
