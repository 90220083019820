// extracted by mini-css-extract-plugin
export var active = "styles-module--active--9c4ec";
export var background = "styles-module--background--1584d";
export var container = "styles-module--container--42be6";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var logo = "styles-module--logo--a1acf";
export var matrix = "styles-module--matrix--32d2f";
export var matrixContainer = "styles-module--matrixContainer--089b6";
export var mobile = "400px";
export var node = "styles-module--node--74312";
export var small = "styles-module--small--6572d";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";