import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import gsap from 'gsap';
// import { SliceConfig } from '@livewire/toolkit/components';
import { useBreakpoints, useScroll } from '@livewire/common/hooks';
import { getRandomIntByRange } from '@livewire/common/utils';
import { Grid } from '@livewire/common/components';
import { Logo } from '@livewire/website/src/components';

import * as styles from './styles.module.scss';

const MagicAtf = ({ data: { heading = '', body = '', sliceConfig } }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  const { largeTablet } = useBreakpoints();
  const { scrollY } = useScroll();

  const [currentSlide, setCurrentSlide] = useState(0);

  // ---------------------------------------------------------------------------
  // ref / state

  const containerRef = useRef(null);
  //
  const bannerRef = useRef(null);
  const upperCharRef = useRef(null);
  const lowerCharRef = useRef(null);
  const scaleTextRef = useRef(null);

  const upperCharRefs = Array.from({ length: 4 }, () => useRef(null));
  const lowerCharRefs = Array.from({ length: 7 }, () => useRef(null));

  const [scrollOption, setScrollOption] = useState('scroll');
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [mouse, setMouse] = useState({
    x: 0,
    y: 0,
    centerX: 0.5,
    centerY: 0.5
  });

  // ---------------------------------------------------------------------------
  // methods

  const getFontVariationSettings = (weightFactor: number) => {
    const defaultWeight = 600;
    const weight = 400 + parseInt(defaultWeight * weightFactor);

    return `'wght' ${weight}, 'wdth' 100, 'ital' 0`;
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    const option = largeTablet ? `cursor` : `scroll`;
    setScrollOption(option);
  }, [largeTablet]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    upperCharRefs.forEach((charRef, index) => {
      if (!largeTablet) {
        charRef.current.transformFactor = 5;
        return;
      }

      if (scrollOption === `cursor`) {
        charRef.current.transformFactor = getRandomIntByRange(15, 25);
      } else {
        charRef.current.transformFactor = getRandomIntByRange(5, 10);
      }
    });
    lowerCharRefs.forEach((charRef, index) => {
      if (!largeTablet) {
        charRef.current.transformFactor = 5;
        return;
      }

      if (scrollOption === `cursor`) {
        charRef.current.transformFactor = getRandomIntByRange(15, 25);
      } else {
        charRef.current.transformFactor = getRandomIntByRange(5, 10);
      }
    });

    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    });

    const handleMouseMove = (e: MouseEvent) => {
      const containerWidth = window.innerWidth;
      const containerHeight = window.innerHeight;

      const x = e.clientX;
      const y = e.clientY;

      const normalizedX = x / containerWidth;
      const normalizedY = y / containerHeight;

      const centerX = normalizedX - 0.5;
      const centerY = normalizedY - 0.5;

      setMouse({
        x,
        y,
        centerX,
        centerY
      });
    };
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    if (!containerRef?.current || !scaleTextRef?.current) {
      return;
    }

    setCurrentSlide(scrollY < 200 ? 0 : 1);
  }, [containerRef, scaleTextRef, scrollY]);

  // ---------------------------------------------------------------------------
  // option 1: cursor

  useEffect(() => {
    if (scrollOption !== `cursor` || !mouse?.centerX || !mouse?.centerY) {
      return;
    }

    upperCharRefs.forEach((charRef, index) => {
      gsap.killTweensOf(charRef.current);

      const variationSettings = getFontVariationSettings(
        mouse.centerX * charRef.current.transformFactor * 0.1
      );

      gsap.to(charRef.current, {
        'font-variation-settings': variationSettings,
        duration: 1,
        ease: 'power4.out'
      });
    });

    lowerCharRefs.forEach((charRef, index) => {
      gsap.killTweensOf(charRef.current);

      const variationSettings = getFontVariationSettings(
        -mouse.centerX * charRef.current.transformFactor * 0.1
      );

      gsap.to(charRef.current, {
        'font-variation-settings': variationSettings,
        duration: 1,
        ease: 'power4.out'
      });
    });
  }, [mouse, lowerCharRefs, upperCharRefs]);

  useEffect(() => {
    if (
      currentSlide === 0 ||
      !containerRef?.current ||
      !upperCharRef?.current ||
      !lowerCharRef?.current ||
      dimensions?.width === 0
    ) {
      return;
    }

    const factor = largeTablet ? 100 : 180;

    let scrollPercent =
      scrollY /
      (containerRef?.current?.getBoundingClientRect()?.height -
        dimensions?.height);

    if (scrollPercent < 0) {
      scrollPercent = 0;
    } else if (scrollPercent > 1) {
      scrollPercent = 1;
    }

    gsap.killTweensOf(scaleTextRef.current);

    if (scrollOption === `cursor`) {
      gsap.to(scaleTextRef.current, {
        y: 100 + -scrollPercent * factor,
        duration: 0.3,
        ease: 'power2.out'
      });
    }

    // -------------------------------------------------------------------------

    if (scrollOption === `cursor`) {
      return;
    }

    upperCharRefs.forEach((charRef, index) => {
      gsap.killTweensOf(charRef.current);

      const variationSettings = getFontVariationSettings(
        (largeTablet ? -1 : -2) +
          scrollPercent * charRef.current.transformFactor * 0.5
      );

      gsap.to(charRef.current, {
        'font-variation-settings': variationSettings,
        duration: 0.3,
        ease: 'power2.out'
      });
    });

    lowerCharRefs.forEach((charRef, index) => {
      gsap.killTweensOf(charRef.current);

      const variationSettings = getFontVariationSettings(
        (largeTablet ? -1 : -2) +
          scrollPercent * charRef.current.transformFactor * 0.5
      );

      gsap.to(charRef.current, {
        'font-variation-settings': variationSettings,
        duration: 0.3,
        ease: 'power2.out'
      });
    });
  }, [
    currentSlide,
    dimensions,
    scaleTextRef,
    scrollY,
    scrollOption,
    largeTablet
  ]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <div className={styles.container} ref={containerRef}>
      <section ref={bannerRef} className={cn(styles.banner)}>
        <Grid>
          <div className={cn(styles.text)}>
            {heading && (
              <h1
                className={cn(`${largeTablet ? `d3` : `h1`}`, styles.heading, {
                  [styles.active]: currentSlide === 0
                })}
              >
                {heading}
              </h1>
            )}
            {body && (
              <p
                className={cn(
                  `${largeTablet ? `h6` : `b2`}`,
                  styles.subheading,
                  {
                    [styles.active]: currentSlide === 0
                  }
                )}
              >
                {body}
              </p>
            )}
          </div>

          <figure className={cn(styles.logo)}>
            {currentSlide === 0 && <Logo color="#CBFE00" />}
          </figure>
        </Grid>
      </section>

      <section className={cn(styles.scaleTextContainer)}>
        <article className={cn(styles.scaleText)}>
          <Grid>
            <div
              ref={scaleTextRef}
              className={cn(styles.scaleTextContent, {
                [styles.active]: currentSlide === 1
              })}
            >
              <h2 className="d2">BE A</h2>
              <h2
                ref={upperCharRef}
                className={cn('d1', styles.scaleTextUpper)}
              >
                {`GAME`.split('').map((letter, index) => (
                  <span
                    key={`scale-text-upper-${index}`}
                    ref={upperCharRefs[index]}
                  >
                    {letter}
                  </span>
                ))}
              </h2>

              <h2
                ref={lowerCharRef}
                className={cn('d1', styles.scaleTextLower)}
              >
                {`CHANGER`.split('').map((letter, index) => (
                  <span
                    key={`scale-text-lower-${index}`}
                    ref={lowerCharRefs[index]}
                  >
                    {letter}
                  </span>
                ))}
              </h2>
            </div>
          </Grid>
        </article>
      </section>
    </div>
  );
};

export default MagicAtf;
